<template>
  <LoadWhenVisible class="collection-push-full-width">
    <div class="flex justify-center">
      <div class="grid-container">
        <VaimoBanner
          v-bind="visualData"
          :ratio="isDesktop ? '136:41' : visualData.ratio"
          parallax
          class="collection-push-full-width__banner mb-md lg:mb-3md col-span-6 lg:col-span-12"
        />
        <div class="col-span-6 lg:col-span-8 lg:col-start-3">
          <VaimoHeading
            v-if="headingData.heading"
            heading-style="emphasized"
            heading-level="h3"
            :heading="headingData.heading"
            :heading-link="headingData.headingLink"
            class="collection-push-full-width__heading text-center mb-xs lg:mb-sm"
          />
          <VaimoTruncatedText
            v-if="headingData.description"
            :text="headingData.description"
            class="collection-push-full-width__description text-center mb-sm"
            truncate="by characters"
            :characters="145"
          />
          <VaimoButtons
            v-if="isButtonsExist"
            :section-data="sectionData"
            class="collection-push-full-width__buttons text-center s:mb-md"
          />
        </div>
        <div class="col-span-6 lg:col-span-12 col-content">
          <VaimoProducts
            :display="productsData.layout"
            :wrapper-props="getWrapperProps"
            :sku-list="productsData.listOfSkus"
            :alt-visuals="productsData.altVisuals"
            loading-type="spinner"
            class="collection-push-full-width__slider"
            unique-key="collection-push-full-width-slider"
          />
        </div>
      </div>
    </div>
  </LoadWhenVisible>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useScreenSize } from '~/diptyqueTheme/composable';
import { useFlexibleSection } from '~/diptyqueTheme/composable/useFlexibleSection';

export default defineComponent({
  name: 'DesktopFullWidth',
  components: {
    LoadWhenVisible,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    VaimoProducts: () => import('organisms/VaimoProducts.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    },
    productsData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { visualData, headingData } = useFlexibleSection(props.sectionData);
    const { isDesktop } = useScreenSize();

    const isButtonsExist = computed(
      () => props.sectionData?.buttonCollection?.items?.length
    );

    const getWrapperProps = computed(() => {
      const breakpoints = [
        {
          maxWidth: 1410,
          maxItemsVisible: 4
        },
        {
          maxWidth: 1148,
          maxItemsVisible: 3
        },
        {
          maxWidth: 530,
          maxItemsVisible: 2
        },
        {
          maxWidth: 360,
          maxItemsVisible: 1
        }
      ];

      return {
        slider: {
          desktopShift: 25,
          desktopQuantityVisible: 5,
          variableWidth: true,
          desktopCenterMode: false,
          responsive: breakpoints.map(({ maxWidth, maxItemsVisible }) => ({
            breakpoint: maxWidth,
            settings: {
              slidesToShow: maxItemsVisible,
              slidesToScroll: 2
            }
          }))
        }
      };
    });

    return {
      visualData,
      isButtonsExist,
      getWrapperProps,
      isDesktop,
      headingData
    };
  }
});
</script>

<style lang="scss" scoped>
.collection-push-full-width {
  .grid-container {
    width: 100%;
  }

  &__banner {
    width: calc(100% + var(--spacer-lg) * 2);
    margin-inline: calc(var(--spacer-lg) * -1);

    @include for-screen-s {
      width: calc(100% + var(--spacer-md) * 2);
      margin-inline: calc(var(--spacer-md) * -1);
    }
  }

  &__heading {
    ::v-deep {
      .vaimo-heading__title {
        @include for-screen-s {
          padding: 0 var(--spacer-md);
        }
      }
    }
  }

  &__description {
    font-size: var(--font-size--lg);
    font-weight: var(--font-weight--light);
  }

  &__slider {
    ::v-deep {
      .vaimo-slider__slider {
        padding: 0;
      }
      .vaimo-slider {
        .slick-slider {
          .slick-list {
            width: fit-content;
            margin: 0 auto;
          }
          .slick-track {
            width: fit-content !important;

            :last-child {
              margin-inline-end: 0;
            }
          }
          .slick-slide {
            width: 242px;
            min-width: 242px;
            margin: 0 20px 0 0;

            @include for-screen-s {
              width: 150px;
              min-width: 150px;
            }
            .sf-product-card {
              padding: 0;
            }
          }
        }
        @include for-screen-s {
          margin-top: 0;
        }
      }
      .vaimo-grid {
        @include for-screen-s {
          padding: 0;
          gap: var(--spacer-3md) var(--spacer-md);
          .sf-product-card {
            padding: 0;
          }
        }
      }
    }
  }
  .col-content {
    min-width: 0;
    width: 100%;
  }
}
</style>
